
import Select from "react-dropdown-select";
import Photoshoot from './Photoshoots';
import Kiriko from './Kiriko';
import Nkossi from './Nkossi';
import WoolFolkYarn from './WoolFolkYarn';



export default function Clients(props) {

var index = props.data.selection

const setupAClient = (index) => {
    var selection = props.data.options[index]
    console.log(selection)
    return <Photoshoot data={selection.collection} />
}

    return (
        <div className="clients-container">
            <h2 className="sub-header">Select Collaboration</h2>
            <div className="drop-down">
                <Select options={props.data.options} onChange={props.data.func} />
            </div>
            { setupAClient(index)}
        </div>
    )
}
