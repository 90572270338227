
import './App.css';
import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import purpleDarkSmile from './assets/purple_guy.gif'
import Home from './components/Home';
import Clients from './components/Clients';
import Digitals from './components/Digitals';
import Photoshoot from './components/Photoshoots';
// import Events from './components/Events';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import React from 'react';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDa9mHZZQwhSPu-mEeu_gg0bFOiRUOkFNg",
  authDomain: "unwontedartistry-portfolio.firebaseapp.com",
  databaseURL: "https://unwontedartistry-portfolio-default-rtdb.firebaseio.com",
  projectId: "unwontedartistry-portfolio",
  storageBucket: "unwontedartistry-portfolio.appspot.com",
  messagingSenderId: "630546841288",
  appId: "1:630546841288:web:758b7ea392bdbca2056ffd",
  measurementId: "G-0D5THN4B21"
};

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: 'white'
};

initializeApp(firebaseConfig);
const storage = getStorage();

const eventsData = listAll(ref(storage, 'events'));

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      reels: [],
      digitals: [],
      photoshoots: [],
      compCard: [],
      kiriko: [],
      woolfolkyarn: [],
      nkossi: [],
      sergedenimes: [],
      selection: 0
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(selection) {
    this.setState(
      {
        selection: selection[0].value
      }
    )
  }

  componentDidMount() {
    listAll(ref(storage, `clients/kiriko`)).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState(
            {
              kiriko: values
            }
          )
        })
    })
      .catch((error) => {
        console.error(error)
      });

    listAll(ref(storage, `clients/sergedenimes`)).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
         .then((values) => {
          this.setState(
            {
              sergedenimes: values
            }
          )
        })
      })
        .catch((error) => {
          console.error(error)
        });

    listAll(ref(storage, `clients/nkossi`)).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState(
            {
              nkossi: values
            }
          )
        })
    })
      .catch((error) => {
        console.error(error)
      });

    listAll(ref(storage, `clients/woolyarnfolk`)).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState(
            {
              woolfolkyarn: values
            }
          )
        })
    })
      .catch((error) => {
        console.error(error)
      });


    listAll(ref(storage, 'digitals')).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState({
            digitals: values
          })
        })
    }).catch((error) => {
      console.error(error)
    });

    listAll(ref(storage, 'mini-photoshoots')).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState({
            photoshoots: values
          })
        })
    }).catch((error) => {
      console.error(error)
    });

    listAll(ref(storage, 'reels')).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState({
            reels: values
          })
        })
    }).catch((error) => {
      console.error(error)
    });

    listAll(ref(storage, 'comp-card')).then((res) => {
      Promise.all(res.items.map(item => getDownloadURL(item)))
        .then((values) => {
          this.setState({
            compCard: values
          })
        })
    }).catch((error) => {
      console.error(error)
    });
  }

  render() {
    return (
      <div className="App">
        <img className='backgroundImg' src={purpleDarkSmile} alt={"alt"} />
        <BrowserRouter>
          <div className="content">
            <h1 className='main-header'><Link to="/" style={linkStyle} >UNWONTED ARTISTRY</Link></h1>
            <br />
            <div className='navBarContainer'>
              <div className='navBarItem'><Link to="/clients" style={linkStyle} >PORTFOLIO</Link></div>
              <div className='navBarItem'><Link to="/digitals" style={linkStyle}>DIGITALS</Link></div>
              <div className='navBarItem'><Link to="/photoshoots" style={linkStyle}>PHOTOSHOOTS</Link></div>
              {/* <div className='navBarItem'><Link to="/events" style={linkStyle}>EVENTS</Link></div> */}
            </div>

            <Navbar bg="dark" variant="dark">
              <Container>
                <Navbar.Brand href="#home">
                  <img
                    alt=""
                    src="../white_arrow_down.png"
                    width="64"
                    height="64"
                    className="d-inline-block align-top"
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>

          </div>
          <Routes>
            <Route path='/clients'
              element={<Clients data={
                {
                  func: this.handleOnChange,
                  selection: this.state.selection,
                  options: [
                    {
                      label: "Kiriko Made",
                      collection: this.state.kiriko,
                      body: "",
                      value: 0
                    },
                    {
                      label: "WoolFolk Yarn",
                      collection: this.state.woolfolkyarn,
                      body: "",
                      value: 1
                    },
                    {
                      label: "Nkossi Boutique",
                      collection: this.state.nkossi,
                      body: "",
                      value: 2
                    },
                    {
                      label: "Serge DeNimes",
                      collection:this.state.sergedenimes,
                      body: "",
                      value: 3
                    }
                  ]
                }
              } />}></Route>
            <Route path='/' element=
              {
                <Home
                  data={
                    {
                      reels: this.state.reels
                    }
                  }
                />
              }>
            </Route>
            <Route path='/digitals' element={<Digitals data={
              {
                digitals: this.state.digitals,
                compCard: this.state.compCard
              }
              } />}></Route>
            <Route path='/photoshoots' element={<Photoshoot data={this.state.photoshoots} />}></Route>

          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
