
export default function Home(props) {
    return (
        <div className="homeContainer">
            <div className="video-grid">
                <h2 className="sub-header">Reels & Runway</h2>
                <iframe className="video-one" width="560" height="315" src="https://youtube.com/embed/xtqhtsicnDk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe className="video-two" width="560" height="315" src="https://youtube.com/embed/Ld5l5YpROfQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>  
                <h2 className="sub-header">Extras</h2>
                <iframe className="video-three" width="560" height="315" src="https://www.youtube.com/embed/2-6QZPxsysk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}