
export default function Digitals(props) {

    return (
        <div className="digitals-container">
            <br/>
            <h2 className="sub-header">Digitals & Comp Cards </h2>
            <div className="comp-card-container">
                {
                    props.data.compCard.map((url, index) =>
                        <div key={index}>
                            <img src={url} alt={index} />
                        </div>
                    )
                }
            </div>
            <div className="image-grid">
                {
                    props.data.digitals.map((url, index) =>
                        <div className="image-grid-item">
                            <img width={500} height={750} key={index} src={url} alt={index} />
                        </div>)
                }
            </div>
        </div>
    )
}