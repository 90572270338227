import { Carousel } from '3d-react-carousal';

export default function Photoshoots(props) {
    var slides = []
    props.data.map((path, index) =>
        slides.push(<img key={index} src={path} alt={index} />)
    )

    const setup = () => {
        if (props.data.length > 0) {
            return <Carousel slides={slides} autoplay={true} interval={3000} />
        }
    }

    return (
        <div>
            <div className="image-grid">
                { setup() }
            </div>
        </div>
    )
}
